<template>
  <div class="containers">
    <navbar></navbar>
    <div class="contents p-15">
      <van-row class="t-center d-flex ai-center">
        <van-col span="8"></van-col>
        <van-col span="8" class="bold fs-xxl">{{
          $t("home.myNFTCard")
        }}</van-col>
        <van-col span="8"
          ><van-button
            color="#E67B06"
            size="mini"
            class="purchase"
            @click="toRecord()"
            >{{ $t("myNFTCard.record") }}</van-button
          ></van-col
        >
      </van-row>
      <div class="borders topbox d-flex ai-center jc-between m-t-20">
        <div class="fs-md m-l-20">
          <div class="d-flex ai-center">
            <p class="m-r-10">{{ $t("myNFTCard.income") }}</p>
            <van-image
              width="15px"
              height="17px"
              @click="toRecord2(1)"
              :src="require('@/assets/file_icon.png')"
            ></van-image>
          </div>
          <van-skeleton
            :row="1"
            :loading="balanceloading"
            row-width="50px"
            class="m-t-15"
          >
            <!-- 总质押收益 -->
            <p class="m-t-15">
              <span class="t-color5">{{ myEarn.dig_earn | cutZero }}</span>
              ACDC
            </p>
          </van-skeleton>
        </div>
        <!-- 未质押时，收矿按钮置灰；
             质押成功时,显示倒计时;
             倒计时结束时刷新当前页面，出现收矿按钮 -->
        <!-- pledge_status 0-未质押 1-已质押  -->
        <van-button
          v-if="isCollect"
          color="#074CE7"
          class="btn"
          :disabled="myEarn.pledge_status == 0"
          :loading="isCLoading"
          @click="collect()"
          ><span class="fs-md">{{
            $t("myNFTCard.collectmine")
          }}</span></van-button
        >
        <div
          v-else
          class="px-15 py-5 m-r-20"
          style="background: #074ce7; border-radius: 5px"
        >
          <van-count-down
            :time="getValue(myEarn.collect_time)"
            class="t-white"
            @finish.once="onfinish"
          />
        </div>
      </div>
      <div class="borders topbox d-flex ai-center jc-between m-t-20">
        <div class="fs-md m-l-20">
          <p class="m-r-10">{{ $t("myNFTCard.balance") }}</p>
          <van-skeleton
            :row="1"
            :loading="balanceloading"
            row-width="50px"
            class="m-t-15"
          >
            <p class="m-t-15">
              <!-- 我的余额 -->
              <span class="t-color5">{{ myEarn.balance }}</span> ACDC
            </p>
          </van-skeleton>
        </div>
        <van-button color="#074CE7" class="btn" @click="toextract"
          ><span class="fs-md">{{ $t("myNFTCard.extract") }}</span></van-button
        >
      </div>
      <van-button
        color="#6834FB"
        :icon="require('@/assets/hecheng.png')"
        class="bigbtn"
        @click="tosynthetic"
        ><span class="fs-lg">{{ $t("myNFTCard.synthetic") }}</span></van-button
      >
      <!-- My card -->
      <div class="d-flex ai-center jc-between">
        <p class="fs-xxl bold">
          <!-- {{ $t("myNFTCard.staking") }} -->
          {{ $t("myNFTCard.mycard") }}
          <span class="fs-lg t-color2 m-l-10"
            >{{ cardInfo.pledge_count }}/{{ cardInfo.total }}
          </span>
        </p>
        <van-popover
          v-model="showAll"
          trigger="click"
          :actions="allList"
          theme="dark"
          @select="onSelectAll"
          class="popover"
        >
          <template #reference>
            <div class="allist d-flex ai-center jc-center borders">
              <span class="fs-md">{{ allList[index].text }}</span>
              <van-image
                width="8px"
                height="6px"
                :src="require('@/assets/arrow@2x.png')"
                class="m-l-5"
              />
            </div>
          </template>
        </van-popover>
      </div>
      <div class="pullrefresh">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          :pulling-text="$t('public.pulling')"
          :loosing-text="$t('public.loosing')"
          :loading-text="$t('public.loading')"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('public.nomores')"
            :loading-text="$t('public.loading')"
            :offset="100"
            @load="onLoad"
          >
            <div class="d-flex flex-wrap jc-between px-10 py-20">
              <div
                v-for="(item, index) in list"
                :key="index"
                @click="toCardDetail(item)"
                class="card_item m-b-20"
              >
                <van-image
                  width="100%"
                  height="210px"
                  :src="require('@/assets/gif/border' + item.level + '.png')"
                />
                <van-image
                  width="100%"
                  height="210px"
                  class="img p-5"
                  v-if="item.store"
                  :src="item.store.remote_url"
                />
                <!-- 0=默认未质押，1=质押中，2=已质押 -->
                <van-image
                  width="50px"
                  height="46px"
                  class="icon"
                  v-if="item.pledge_status != 0"
                  :src="require('@/assets/staking.png')"
                />
                <!-- 0=默认，1挂卖中 -->
                <van-image
                  class="icon"
                  width="50px"
                  height="46px"
                  v-if="item.sale_status == 1"
                  :src="require('@/assets/selling.png')"
                />
                <!-- 确认中状态 -->
                <van-image
                  class="icon"
                  width="50px"
                  height="46px"
                  v-if="
                    (item.order && item.order.status == 0) ||
                    item.chain_status != 2 ||
                    item.pledge_status == 1
                  "
                  :src="require('@/assets/confirm_icon.png')"
                />
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <!-- 提示收矿数量弹框 -->
    <van-popup v-model="isShow" overlay-class="overlay2" class="popup">
      <div class="borders fs-xxl d-flex ai-center jc-center">
        <p>
          <span class="t-color5">{{ total_reward }}</span> ACDC
          {{ $t("myNFTCard.charged") }}
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      totalPage: 1,
      pageNumber: 1,
      list: [],

      showAll: false,
      index: 0,
      cardInfo: {},
      myEarn: {}, //我的收益
      balanceloading: false,
      typeindex: null,
      isCollect: true, //是否可以收矿
      isCLoading: false,
      isShow: false,
      total_reward: "", //总收取数量
    };
  },
  computed: {
    allList() {
      return [
        { index: 0, text: this.$t("myNFTCard.allCards") },
        { index: 1, text: this.$t("myNFTCard.notstaking") },
        { index: 2, text: this.$t("myNFTCard.staking") },
      ];
    },
  },
  mounted() {
    this.init();
    this.getmyEarn();
  },
  methods: {
    getValue(time) {
      if (time) {
        var time1 = new Date().getTime(); //当前时间转换为时间戳
        var time2 = new Date(time.replace(/-/g, "/"));
        var time3 = time2.getTime();
        var runTime = ((time3 - time1) / 1000).toFixed(0); //开始得出时间差,.toFixed(0)保留小数点后0位## 标题
        return runTime * 1000;
      }
    },
    // 卡牌记录
    toRecord() {
      this.$router.push("/record");
    },
    //质押记录
    toRecord2(e) {
      this.$router.push(`/extractRecord?i=${e}`);
    },
    //选择卡牌
    onSelectAll(e) {
      this.index = e.index;
      this.finished = false;
      this.pageNumber = 1;
      this.totalPage = 1;
      // this.list = [];
      // this.init();
      if (e.index == 1) {
        this.typeindex = 1;
        // this.getList(1);
      } else if (e.index == 2) {
        this.typeindex = 2;
        // this.getList(2);
      } else {
        this.typeindex = null;
        // this.getList();
      }
      this.getList();
    },
    // 收矿
    collect() {
      // this.$router.push("/collectMine");
      this.isCLoading = true;
      this.$axios
        .post(this.$api.one_collect, {})
        .then((res) => {
          if (res.code == 200) {
            this.isCLoading = false;
            this.total_reward = res.data.total_reward;
            this.isShow = !this.isShow;
            setTimeout(() => {
              this.getmyEarn();
            }, 500);
          } else {
            this.isCLoading = false;
            this.$toast(res.msg);
            setTimeout(() => {
              this.getmyEarn();
            }, 500);
          }
        })
        .catch((err) => {
          this.isCLoading = false;
        });
    },
    //倒计时结束
    onfinish() {
      setTimeout(() => {
        this.getmyEarn();
      }, 1000);
    },
    //跳转合成卡牌
    tosynthetic() {
      this.$router.push("/synthetic");
    },
    //提取
    toextract() {
      this.$router.push("/extract");
    },
    //跳转卡片详情
    toCardDetail(item) {
      this.$router.push({
        path: "/mycardDetail",
        query: { id: item.id, pledge_id: this.myEarn.pledge_card_id },
      });
    },
    //我的收益
    getmyEarn() {
      this.balanceloading = true;
      this.$axios.get(this.$api.myEarn, {}).then((res) => {
        // pledge_status 0-未质押 1-已质押
        if (res.data.pledge_status == 1) {
          if (this.getValue(res.data.collect_time) <= 0) {
            //收矿时间超过当前时间   可以收矿
            this.isCollect = true;
          } else {
            this.isCollect = false;
          }
        }
        this.myEarn = res.data;
        this.balanceloading = false;
      });
    },
    //页面初始化之后会触发一次，在页面往下加载的过程中会多次调用【上拉加载】
    onLoad() {
      setTimeout(() => {
        if (this.pageNumber >= this.totalPage) {
          this.finished = true;
          this.loading = false;
        } else {
          this.pageNumber++;
          this.getList();
        }
      }, 500);
    },
    init() {
      this.totalPage = 1;
      this.pageNumber = 1;
      this.getList();
    },
    onRefresh() {
      setTimeout(() => {
        this.totalPage = 1;
        this.pageNumber = 1;
        this.getList();
      }, 1000);
    },
    getList(status) {
      let that = this;
      this.loading = true;
      this.$axios
        .get(this.$api.usercards, {
          page: this.pageNumber,
          page_size: 8,
          pledge_status: this.typeindex, // 1-未质押 2-已质押
        })
        .then((res) => {
          if (res.code == 200) {
            that.$toast.clear();
            this.loading = false;
            this.finished = false;
            this.refreshing = false;
            if (!this.typeindex) {
              this.cardInfo = res.data;
            }
            let arr = res.data.list;
            if (this.pageNumber == 1) {
              this.list = arr;
              this.totalPage = res.data.totalPage;
            } else {
              this.list.push(...arr);
            }
          } else {
            this.loading = false;
            that.$toast(res.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
/deep/ .van-skeleton {
  padding: 0;
}
/deep/.van-count-down {
  font-size: 13px;
  letter-spacing: 1px;
}
.purchase {
  border-radius: 5px;
}
.btn {
  min-width: 90px;
  height: 25px;
  border-radius: 5px;
  margin-right: 20px;
}
.topbox {
  min-height: 80px;
}
.bigbtn {
  width: 100%;
  height: 35px;
  margin: 15px 0 25px 0;
  border-radius: 5px;
}
.allist {
  min-width: 85px;
  height: 25px;
  border-radius: 12px;
}
.card_item {
  width: 47%;
  position: relative;
  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }
  .img {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.popup {
  .borders {
    width: 210px;
    height: 80px;
  }
}
</style>